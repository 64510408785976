import React from 'react';
import './CustomCursor.scss'

const CustomCursor = ({customCursorRef, title, hide}) => (
    <div id="custom-cursor" className={hide ? 'hide' : ''} ref={customCursorRef}>
        {title}
    </div>
)

export default CustomCursor
