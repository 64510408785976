import React from 'react';
import './Footer.scss'
import LanguageSelector from "./LanguageSelector";
import {videoStep} from "../constants";

const Footer = ({progress, currentLanguage, setIsNormalCursor}) => (
    <footer className={progress === videoStep ? 'hide' : ''}>
        <LanguageSelector currentLanguage={currentLanguage} setIsNormalCursor={setIsNormalCursor}/>
    </footer>
)

export default Footer
