import React, {useState} from 'react';
import './LanguageSelector.scss'
import {ReactComponent as Chevron} from '../assets/chrevron-down.svg'
import {CSSTransition} from "react-transition-group";

const LanguageSelector = ({currentLanguage, setIsNormalCursor}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSelector = (e) => {
        e.stopPropagation();

        setIsOpen(!isOpen);
    }

    return (
        <section className="language-selector" onMouseEnter={() => setIsNormalCursor(true)} onMouseLeave={() => setIsNormalCursor(false)}>
            <div className={'dropdown' + (isOpen ? ' show' : '')}>
                <CSSTransition in={isOpen} timeout={500} classNames="content" unmountOnExit>
                    <div className="dropdown-content">
                        {currentLanguage === 'no' ? <a href="/en">EN</a> : <a href="/">NO</a>}
                    </div>
                </CSSTransition>
                <button onClick={toggleSelector}>{currentLanguage && currentLanguage.toUpperCase()}<Chevron /></button>
            </div>
        </section>
    )
}

export default LanguageSelector
