import React from 'react';
import './FrontPage.scss';
import NextButton from './NextButton';

const FrontPage = ({title, subtitle, buttonText}) => (
    <div className="front-page page">
        <h1>{title}</h1>
        <h2>{subtitle.split('\n').map((s, index) => <span key={index}>{s}<br /></span>)}</h2>
        <NextButton buttonText={buttonText} />
    </div>
)

export default FrontPage
