import './App.scss';
import Footer from "./components/Footer";
import React, {useRef, useState} from "react";
import FrontPage from "./components/FrontPage";
import TextPage from "./components/TextPage";
import {CSSTransition} from "react-transition-group";
import VideoPage from "./components/VideoPage";
import {numSteps, smallBreakpoint, translations, videoStep, videoURL} from "./constants";
import Header from "./components/Header";
import CustomCursor from "./components/CustomCursor";
import NextButton from "./components/NextButton";
import CircleCursor from "./components/CircleCursor";

function App() {
    const timeout = 1500;
    const customCursorRef = useRef();
    const videoClipRef = useRef();
    const [isNormalCursor, setIsNormalCursor] = useState(false);
    const [progress, setProgress] = useState(0);
    const pathComponents = window.location.pathname.split('/');
    const language = pathComponents && pathComponents.length > 1 && pathComponents[1] === 'en' ? 'en' : 'no';
    const [showVideoButtons, setShowVideoButtons] = useState(false);
    const text = translations[language];
    let videoTimeout = null;
    const [circleCursor, setCircleCursor] = useState(false);
    const circleCursorRef = useRef();

    const goToNextSlide = () => {
        if (window.innerWidth > smallBreakpoint && progress < videoStep) {
            goToProgress(progress + 1);
        }
    }

    window.onpopstate = (e) => {
        if (e.state && e.state.progress) {
            setProgress(parseInt(e.state.progress) || 0);
        } else {
            setProgress(0);
        }
    }

    const goToProgress = (progress) => {
        const newProgress = Math.max(Math.min(progress, numSteps - 1), 0);
        setProgress(newProgress);
        window.history.pushState({progress: newProgress}, null, null);
    }

    const buttonClick = () => {
        if(progress <= videoStep) {
            goToProgress(progress + 1);
        }
        else if (progress === numSteps-1) {
            window.location.href = text.outboundLink.href;
        }
    }

    const startOver = () => {
        goToProgress(0);
    }

    const displayButtons = () => {
        if (window.innerWidth > smallBreakpoint && progress === videoStep) {
            setShowVideoButtons(true);
        }
    }

    const hideButtons = () => {
        setShowVideoButtons(false);
    }

    const hideCircleCursor = () => {
        setCircleCursor(false);
    }

    const mouseMove = (e) => {
        customCursorRef.current.style.top = e.clientY + 'px';
        customCursorRef.current.style.left = e.clientX + 'px';
        videoClipRef.current.style.clipPath = 'circle(40px at ' + e.clientX + 'px ' + e.clientY + 'px)';

        if (progress === videoStep) {
            clearTimeout(videoTimeout);
            videoTimeout = setTimeout(function () { hideButtons(); hideCircleCursor(); }, 3000);

            setShowVideoButtons(true);

            setCircleCursor(true);
            circleCursorRef.current.style.top = e.clientY - 16 + 'px';
            circleCursorRef.current.style.left = e.clientX - 16 + 'px';
        }
    }

    let classnames = '';
    if (progress < videoStep || isNormalCursor || (progress === videoStep)) {
        classnames += 'hide-cursor';
    }
    if (showVideoButtons) {
        classnames += ' show-video-buttons';
    }

    return (
        <div id="app" className= {classnames} onClick={()=>{ goToNextSlide(); displayButtons()}} onMouseMove={mouseMove}>
            <VideoPage progress={progress} buttonText={text.next} clickNextHandler={buttonClick}/>

            <Header progress={progress}/>

            <CSSTransition in={progress === 0} timeout={timeout} classNames="page" unmountOnExit>
                <FrontPage title={text.title} subtitle={text.subtitle}/>
            </CSSTransition>

            <CSSTransition in={progress === 1} timeout={timeout} classNames="page" unmountOnExit>
                <TextPage text={text.textContent[0]} progress={1}/>
            </CSSTransition>

            <CSSTransition in={progress === 2} timeout={timeout} classNames="page" unmountOnExit>
                <TextPage text={text.textContent[1]} progress={2}/>
            </CSSTransition>

            <CSSTransition in={progress === 3} timeout={timeout} classNames="page" unmountOnExit>
                <TextPage text={text.textContent[2]} icons={{headphonesTitle: text.headphonesTitle, rotateTitle: text.rotateTitle}} progress={3}/>
            </CSSTransition>

            <CSSTransition in={progress === 5} timeout={timeout} classNames="page" unmountOnExit>
                <TextPage text={text.textContent[3]} link={text.outboundLink} buttonText={text.start_over} clickStartOverHandler={startOver} noScale={true} progress={5}/>
            </CSSTransition>

            <Footer progress={progress} currentLanguage={language} setIsNormalCursor={setIsNormalCursor}/>

            <div className={'video-clip step-' + progress + (progress >= videoStep || isNormalCursor ? ' hide' : '')} ref={videoClipRef}>
                <video playsInline>
                    <source src={videoURL} type="video/mp4" />
                </video>
                <div className="overlay"></div>
            </div>

            <CustomCursor
                customCursorRef={customCursorRef}
                title={progress < videoStep - 1 ? text.next : text.play}
                hide={progress >= videoStep || isNormalCursor}/>

            <NextButton click={buttonClick}
                        title={progress < videoStep - 1 ? text.next : progress === numSteps-1 ? text.readMore : text.play}
                        hide={progress >= videoStep && progress < numSteps-1}
                        link={progress === numSteps-1 ? text.outboundLink.href : null}/>

            <CircleCursor
                circleCursorRef={circleCursorRef} 
                 show={window.innerWidth > smallBreakpoint && circleCursor && progress === videoStep}/>
        </div>
    );
}

export default App;
