
export const videoStep = 4;
export const numSteps = 6;
export const smallBreakpoint = 600;

export const videoURL = 'https://assets.grensesnitt.cloud/hamran/vandring.mp4';

export const translations = {
    no: {
        title: 'Vandring',
        subtitle: 'En skogsopplevelse,\nlydsatt av André Bratten.',
        textContent: [
            'Å vandre i skogen er å ta seg tid. Tid til å se, lytte, lukte, kjenne, utforske. Til å stå helt stille og merke, til å bare være. Vi mennesker er naturlige skapninger, og vi får det bedre av å omgi oss med vill natur. Samtidig har vi et ansvar for at naturen har det bra med oss mennesker.',
            'Skogen utsondrer helsefremmende stoffer. Og vi kjenner jo at det hjelper på kropp og sjel å være ute blant trær og mose, å høre løvet rasle og fuglene synge. Roen vi opplever i skogen senker pulsen, og gir rom for ettertanke, oppmerksomhet og omsorg, i møte med alt som er levende.',
            'Ta deg en pause. Bli med inn i skogen – det suser i tretoppene. ',
            'Vi skal også gi trærne den tiden de trenger. Slik vi nå forvalter skogen, er sørlandsk trevirke en fornybar ressurs, for vi høster ikke mer enn vi har bruk for. Og ikke mer enn skogen rundt oss kan tåle. Det vi så lager av treet – ting som holder i generasjoner – blir varige ekko av den skogen vi ferdes i.\n\nNaturen taler gjennom materialene.\nOg vi fortsetter å trø varsomt.'
        ],
        outboundLink: {
            title: 'Les mer om hvordan vi jobber med tre som materiale',
            href: 'https://hamran.no/sorlandsk-eik/'
        },
        next: 'Neste',
        start_over: 'Start på ny',
        play: 'Spill av',
        readMore: 'Les mer',
        headphonesTitle: 'Hodetelefoner er anbefalt',
        rotateTitle: 'Vend telefonen'
    },
    en: {
        title: 'Wander',
        subtitle: 'A forest experience.\nMusic by André Bratten.',
        textContent: [
            'Wandering the woods means taking the time – the time to see, listen, smell, feel, explore. To stay completely still and mindful, to simply be. Humans are natural creatures, and being out in nature is good for us. At the same time, we have a responsibility to ensure nature\'s well-being.',
            'The forest emits health-promoting substances; to be out among the trees and moss, to hear the leaves rustling and the birds singing, speaks to both body and mind. The peace we experience in the forest lowers the heart rate and allows for reflection, attention and care towards everything living.',
            'Pause for a moment. Come with us into the forest - the treetops are rustling.',
            'We must also give the trees the time they need. The way we now manage our forests, local hardwood is a renewable resource. We only harvest what we require, and no more than the forests around us can sustain. Everything we then make from the materials – things that last for generations – becomes enduring echoes of the woods we wander in.\n\nNature speaks through the materials.\nAnd we continue to tread carefully.'
        ],
        outboundLink: {
            title: 'Read more about how we work with wood as a material',
            href: 'https://hamran.no/en/southern-oak/'
        },
        next: 'Next',
        start_over: 'Start over',
        play: 'Play',
        readMore: 'Read more',
        headphonesTitle: 'Headphones are recommended',
        rotateTitle: 'Rotate the phone'
    }
}
