import React from 'react';
import './NextButton.scss';

const NextButton = ({click, title, hide, link}) => {
    return (
        link ?
            <a id="circle-button" href={link}>{title}</a>
            :
            <button id="circle-button" className={hide ? 'hide' : ''} onClick={click}>{title}</button>
    )
}

export default NextButton
