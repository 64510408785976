import React from 'react';
import './Header.scss'
import {videoStep} from "../constants";
import {ReactComponent as HamranLogo} from '../assets/hamran-logo.svg'

const Header = ({progress}) => (
    <header className={progress === videoStep ? 'hide' : ''}>
        <h1>
            <HamranLogo />
        </h1>
    </header>
)

export default Header
