import React, {useEffect, useRef, useState} from 'react'
import './VideoPage.scss'
import {videoStep, videoURL} from '../constants'
import {ReactComponent as Pause} from '../assets/pause.svg'
import {ReactComponent as Play} from '../assets/play.svg'

const VideoPage = ({progress, buttonText, clickNextHandler}) => {
    const playerRef = useRef();
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        if (progress === videoStep) {
            playerRef.current.volume = 1;
            playerRef.current.play();
        } else if (progress !== videoStep) {
            playerRef.current.pause();
        }

        if (progress === 0) {
            playerRef.current.currentTime = 0;
        }
    }, [progress]);

    const pausePlayVideo = () => {
        if (isPaused) {
            playerRef.current.play();
            setIsPaused(false);
        }
        else {
            playerRef.current.pause();
            setIsPaused(true);
        }
    }

    const clickNext = (e) => {
        e.stopPropagation();

        clickNextHandler();
    }

    const videoEnded = () => {
        clickNextHandler();
    }

    return (
        <div className={'video-page step-' + progress} onClick={pausePlayVideo}>
            <video ref={playerRef} onEnded={videoEnded} poster="/images/vandring-poster.jpg">
                <source src={videoURL} type="video/mp4"/>
            </video>
            <div className="overlay" />
            <button id="pause-play-button" className={isPaused ? 'videoPaused' : ''}>
                {isPaused ? <Play /> : <Pause />}
            </button>
            <button className="next" onClick={clickNext}>{buttonText}</button>
        </div>
    )
}

export default VideoPage
