import React from 'react';
import './TextPage.scss'
import {ReactComponent as ArrowRight} from "../assets/arrow-right.svg";
import {ReactComponent as Headphones} from '../assets/headphones.svg'
import {ReactComponent as Rotate} from '../assets/rotate.svg'

const TextPage = ({text, link, icons, buttonText, progress, clickStartOverHandler, noScale}) => {

    const clickStartOver = (e) => {
        e.stopPropagation();

        clickStartOverHandler();
    }

    return (
        <div className={'text-page page step-' + progress + (noScale ? ' no-scale' : '')}>
            {text && <p>{text.split('\n').map((t, index) => <span key={index}>{t}<br /></span>)}</p>}

            {link && <section className="link">
                <a href={link.href}>
                    <span>{link.title}</span>
                    <ArrowRight />
                </a>
            </section>}

            {icons && <section className="icons">
                <div className="headphones-icon">
                    <Headphones />
                    <span>{icons.headphonesTitle}</span>
                </div>
                <div className="rotate-icon">
                    <Rotate />
                    <span>{icons.rotateTitle}</span>
                </div>
            </section>}

            {buttonText && <button className="start-over" onClick={clickStartOver}>{buttonText}</button>}
        </div>
    )
}

export default TextPage
